import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

/**
 * Creat .glb file in dist folder
 */
// const firstEngineURL = new URL('../assets/motorzinho.glb', import.meta.url);
const motor_um = new URL('../assets/motor_um.glb', import.meta.url);
const motor_dois = new URL('../assets/motor_dois.glb', import.meta.url);
const motor_tres = new URL('../assets/motor_tres.glb', import.meta.url);
const motor_quatro = new URL('../assets/motor_quatro.glb', import.meta.url);

/**
 * Renderer and scene
 */
const renderer = new THREE.WebGLRenderer({canvas: document.getElementById("firstCanvas"), antialias: true});



//const tabs = document.getElementById()




renderer.shadowMap.enabled = true;

renderer.setSize( window.innerWidth, window.innerHeight);





const scene0 = new THREE.Scene();
const scene1 = new THREE.Scene();
const scene2 = new THREE.Scene();
const scene3 = new THREE.Scene();
const scene4 = new THREE.Scene();


window.currentScene = scene0;

/**
 * Camera
 */
const camera = new THREE.PerspectiveCamera(
    50,
    window.innerWidth / window.innerHeight,
    0.1,
    1000
);
camera.position.set(-1, 3.5, 7);

/**
 * Lights
 */
// for (let i= 0; i < 5; i++) {
//     const ambientLight[i] = new THREE.AmbientLight(0xffffff);
//     scene.add(ambientLight[i]);

//     const directionalLight1[i] = new THREE.DirectionalLight(0xffffff, 1);
//     const directionalLight2[i] = new THREE.DirectionalLight(0xffffff, 1);
//     const directionalLight3[i] = new THREE.DirectionalLight(0xffffff, 1);
//     const directionalLight4[i] = new THREE.DirectionalLight(0xffffff, 1);

//     scene[i].add(directionalLight1[i]);
//     scene[i].add(directionalLight2[i]);
//     scene[i].add(directionalLight3[i]);
//     scene[i].add(directionalLight4[i]);

//     directionalLight1[i].position.set(0, 5, 7.5);
//     directionalLight2[i].position.set(-7.8, 10, 0);
//     directionalLight3[i].position.set(0, 5, -8.3);
//     directionalLight4[i].position.set(5, 10, 0);

//     directionalLight1[i].castShadow = true;
//     directionalLight2[i].castShadow = true;
//     directionalLight3[i].castShadow = true;
//     directionalLight4[i].castShadow = true;

// }


const ambientLight0 = new THREE.AmbientLight(0xffffff, 2); //Ambient Light
const ambientLight1 = new THREE.AmbientLight(0xffffff, 2); //Ambient Light
const ambientLight2 = new THREE.AmbientLight(0xffffff, 2); //Ambient Light
const ambientLight3 = new THREE.AmbientLight(0xffffff, 2); //Ambient Light
const ambientLight4 = new THREE.AmbientLight(0xffffff, 2); //Ambient Light
scene0.add(ambientLight0);
scene1.add(ambientLight1);
scene2.add(ambientLight2);
scene3.add(ambientLight3);
scene4.add(ambientLight4);

const directionalLight10 = new THREE.DirectionalLight(0xffffff, 1.2);
const directionalLight20 = new THREE.DirectionalLight(0xffffff, 1.2);
const directionalLight30 = new THREE.DirectionalLight(0xffffff, 1.2);
const directionalLight40 = new THREE.DirectionalLight(0xffffff, 1.2);

const directionalLight11 = new THREE.DirectionalLight(0xffffff, 1.2);
const directionalLight21 = new THREE.DirectionalLight(0xffffff, 1.2);
const directionalLight31 = new THREE.DirectionalLight(0xffffff, 1.2);
const directionalLight41= new THREE.DirectionalLight(0xffffff, 1.2);

const directionalLight12 = new THREE.DirectionalLight(0xffffff, 1.2);
const directionalLight22 = new THREE.DirectionalLight(0xffffff, 1.2);
const directionalLight32 = new THREE.DirectionalLight(0xffffff, 1.2);
const directionalLight42 = new THREE.DirectionalLight(0xffffff, 1.2);

const directionalLight13 = new THREE.DirectionalLight(0xffffff, 1.2);
const directionalLight23 = new THREE.DirectionalLight(0xffffff, 1.2);
const directionalLight33 = new THREE.DirectionalLight(0xffffff, 1.2);
const directionalLight43 = new THREE.DirectionalLight(0xffffff, 1.5);

const directionalLight14 = new THREE.DirectionalLight(0xffffff, 1.2);
const directionalLight24 = new THREE.DirectionalLight(0xffffff, 1.2);
const directionalLight34 = new THREE.DirectionalLight(0xffffff, 1.2);
const directionalLight44 = new THREE.DirectionalLight(0xffffff, 1.2);

scene0.add(directionalLight10);
scene0.add(directionalLight20);
scene0.add(directionalLight30);
scene0.add(directionalLight40);

scene1.add(directionalLight11);
scene1.add(directionalLight21);
scene1.add(directionalLight31);
scene1.add(directionalLight41);

scene2.add(directionalLight12);
scene2.add(directionalLight22);
scene2.add(directionalLight32);
scene2.add(directionalLight42);

scene3.add(directionalLight13);
scene3.add(directionalLight23);
scene3.add(directionalLight33);
scene3.add(directionalLight43);

scene4.add(directionalLight14);
scene4.add(directionalLight24);
scene4.add(directionalLight34);
scene4.add(directionalLight44);


directionalLight10.position.set(0, 5, 7.5);
directionalLight20.position.set(-7.8, 10, 0);
directionalLight30.position.set(0, 5, -8.3);
directionalLight40.position.set(5, 10, 0);

directionalLight11.position.set(0, 5, 7.5);
directionalLight21.position.set(-7.8, 10, 0);
directionalLight31.position.set(0, 5, -8.3);
directionalLight41.position.set(5, 10, 0);

directionalLight12.position.set(0, 5, 7.5);
directionalLight22.position.set(-7.8, 10, 0);
directionalLight32.position.set(0, 5, -8.3);
directionalLight42.position.set(5, 5, 0);

directionalLight13.position.set(0, 5, 7.5);
directionalLight23.position.set(-7.8, 10, 0);
directionalLight33.position.set(0, 5, -8.3);
directionalLight43.position.set(4, 3, 0);

directionalLight14.position.set(0, 5, 7.5);
directionalLight24.position.set(-7.8, 10, 0);
directionalLight34.position.set(0, 5, -8.3);
directionalLight44.position.set(5, 10, 0);


directionalLight10.castShadow = true;
directionalLight20.castShadow = true;
directionalLight30.castShadow = true;
directionalLight40.castShadow = true;

directionalLight11.castShadow = true;
directionalLight21.castShadow = true;
directionalLight31.castShadow = true;
directionalLight41.castShadow = true;

directionalLight12.castShadow = true;
directionalLight22.castShadow = true;
directionalLight32.castShadow = true;
directionalLight42.castShadow = true;

directionalLight13.castShadow = true;
directionalLight23.castShadow = true;
directionalLight33.castShadow = true;
directionalLight43.castShadow = true;

directionalLight14.castShadow = true;
directionalLight24.castShadow = true;
directionalLight34.castShadow = true;
directionalLight44.castShadow = true;

//const directionalLight = new THREE.DirectionalLight(0xffffff, 0.8); // DIrectional Light
//scene.add(directionalLight);
// directionalLight.position.set(-30, 50, 0);
// directionalLight.castShadow = true;
// directionalLight.shadow.camera.bottom = -12;
// directionalLight.shadow.camera.top = 12;

// Drawings
// const dLightHelper1 = new THREE.DirectionalLightHelper(directionalLight1, 3); 
// const dLightHelper2 = new THREE.DirectionalLightHelper(directionalLight2, 3); 
// const dLightHelper3 = new THREE.DirectionalLightHelper(directionalLight3, 3); 
// scene.add(dLightHelper1);
// scene.add(dLightHelper2);
// scene.add(dLightHelper3);
// scene.add(dLightHelper4);

// const dLightShadowHelper = new THREE.CameraHelper(directionalLight2.shadow.camera); // Camera shadow; 
// scene.add(dLightShadowHelper);

/**
 * Orbit Control
 */
 const orbit = new OrbitControls(camera, renderer.domElement);
 orbit.maxDistance = 10;
 orbit.minDistance = 3;
 orbit.enableDamping = true;
 orbit.minPolarAngle = Math.PI / 6;
 orbit.maxPolarAngle = Math.PI / 2.15;
 //orbit.autoRotate = true;
//  orbit.minAzimuthAngle = Math.PI / 4;

/**
 * Axes Helper
 */
// const axesHelper = new THREE.AxesHelper(3);
// scene.add(axesHelper);

/**
 * Grid Helper
 */
//const gridHelper = new THREE.GridHelper(30);
// scene.add(gridHelper);

/**
 * Panel Helper
 */
// const gui = new dat.GUI();
// const options = {
//     sphereColor: '#0000ff',
//     sphereWireframe: false,
//     speed: 0.01,
// };

// gui.addColor(options, 'sphereColor').onChange(function(e) {
//     sphere.material.color.set(e);
// });

// gui.add(options, 'sphereWireframe').onChange(function(e) {
//     sphere.material.wireframe = e;
// })

// gui.add(options, 'speed', 0, 0.1);

// let step = 0;

/**
 * Add Sphere
 */

const sphereGeometry = new THREE.SphereGeometry(4, 50, 50);
const sphereMaterial = new THREE.MeshStandardMaterial({
    color: 0x0000ff,
    wireframe: false,
});
const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
//scene.add(sphere);
sphere.position.set(-10, 10, 0)
sphere.castShadow = true;

/**
 * Add box
 */

 const boxGeometry = new THREE.BoxGeometry();
 const boxMaterial = new THREE.MeshBasicMaterial({color: 0x00ff00});
 const box = new THREE.Mesh(boxGeometry, boxMaterial);
//  scene1.add(box);
 
 const boxGeometry2 = new THREE.BoxGeometry();
 const boxMaterial2 = new THREE.MeshBasicMaterial({color: 0xff0000});
 const box2 = new THREE.Mesh(boxGeometry2, boxMaterial2);
 scene2.add(box2);
 
 const boxGeometry3 = new THREE.BoxGeometry();
 const boxMaterial3 = new THREE.MeshBasicMaterial({color: 0x0000ff});
 const box3 = new THREE.Mesh(boxGeometry3, boxMaterial3);
//  scene3.add(box3);
 
 const boxGeometry4 = new THREE.BoxGeometry();
 const boxMaterial4 = new THREE.MeshBasicMaterial({color: 0x00ffff});
 const box4 = new THREE.Mesh(boxGeometry4, boxMaterial4);
 scene4.add(box4);

/**
 * Load Blender Model
 */
// const assetLoader = new GLTFLoader();
// assetLoader.load(firstEngineURL.href, function(gltf) {
//     const model = gltf.scene;
//     model.children[0].castShadow = true;
//     model.children[1].castShadow = true;
//     model.children[2].castShadow = true;
//     model.children[3].castShadow = true;
//     model.children[4].castShadow = true;
//     model.children[5].castShadow = true;
//     scene0.add(model);
//     model.position.set(0, -0.5, 0);
//     model.castShadow = true;
// }, undefined, function(error) {
//     console.error(error)
// })

const assetLoader = new GLTFLoader();
const assetLoader2 = new GLTFLoader();
const assetLoader3 = new GLTFLoader();
const assetLoader4 = new GLTFLoader();

let mixer;
assetLoader.load(motor_um.href, function(gltf) {
    const model = gltf.scene;   
    mixer = new THREE.AnimationMixer(model);
    const clips = gltf.animations;
    const clip = THREE.AnimationClip.findByName(clips, 'heliceAction');
    const action = mixer.clipAction(clip);
    action.play(); 
    model.children[0].children[0].castShadow = true;
    model.children[0].children[1].castShadow = true;
    model.children[1].castShadow = true;
    model.children[2].castShadow = true;
    model.children[3].children[0].castShadow = true;
    model.children[3].children[0].castShadow = true;
    model.children[4].castShadow = true;
    model.children[5].castShadow = true;
    model.children[5].castShadow = true;
    scene0.add(model);
    model.position.set(0, -0.5, 0);
    model.castShadow = true;
}, undefined, function(error) {
    console.error(error)
})

let mixer2;
assetLoader2.load(motor_dois.href, function(gltf) {
    const model = gltf.scene;
    mixer2 = new THREE.AnimationMixer(model);
    const clips = gltf.animations;
    // const clip = THREE.AnimationClip.findByName(clips, 'heliceAction');
    // const action = mixer2.clipAction(clip);
    // action.play();
    clips.forEach(function(clip) {
        const action = mixer2.clipAction(clip);
        action.play();
    });

    model.children[0].castShadow = true;
    model.children[1].castShadow = true;
    model.children[2].castShadow = true;
    model.children[3].children[0].castShadow = true;
    model.children[3].children[0].castShadow = true;
    model.children[4].castShadow = true;
    model.children[5].castShadow = true;
    model.children[5].castShadow = true;
    scene1.add(model);
    model.position.set(0, -0.5, 0);
    model.castShadow = true;
}, undefined, function(error) {
    console.error(error)
})

let mixer3;

assetLoader3.load(motor_tres.href, function(gltf) {
    const model = gltf.scene;
    mixer3 = new THREE.AnimationMixer(model);
    const clips = gltf.animations;
    const clip = THREE.AnimationClip.findByName(clips, 'heliceAction');
    const action = mixer3.clipAction(clip);
    action.play();

    model.children[0].castShadow = true;
    model.children[2].children[0].castShadow= true;
    model.children[2].children[0].castShadow = true;
    // model.children[1].castShadow = true;
    model.children[4].children[0].castShadow= true;
    model.children[4].children[0].castShadow= true;
    model.children[3].castShadow = true;
    model.children[5].castShadow = true;

    
    model.children[4].children[0].material.metalness = 0.9;
    model.children[4].children[0].material.roughness = 0.5;
    model.children[0].material.metalness = 0.9;
    model.children[0].material.roughness = 0.5;
    // model.children[2].material.metalness = 0.9;
    // model.children[2].material.roughness = 0.5;
    // model.children[4].material.metalness = 0.9;
    // model.children[4].material.roughness = 0.5;
    model.children[5].material.metalness = 0.9;
    model.children[5].material.roughness = 0.5;
    scene2.add(model);
    model.position.set(0, -0.5, 0);
    model.castShadow = true;
}, undefined, function(error) {
    console.error(error)
})

// let mixer3;

assetLoader3.load(motor_quatro.href, function(gltf) {
    const model = gltf.scene;
    console.log(model)
    mixer3 = new THREE.AnimationMixer(model);
    // const clips = gltf.animations;
    // const clip = THREE.AnimationClip.findByName(clips, 'heliceAction');
    // const action = mixer3.clipAction(clip);
    // action.play();

    model.children[0].castShadow = true;
    model.children[3].children[0].castShadow= true;
    model.children[3].children[0].castShadow = true;
    model.children[1].castShadow = true;
    model.children[4].children[0].castShadow= true;
    model.children[4].children[0].castShadow= true;
    model.children[2].castShadow = true;
    model.children[5].castShadow = true;

    
    // model.children[4].children[0].material.metalness = 0.9;
    // model.children[4].children[0].material.roughness = 0.5;
    // model.children[0].material.metalness = 0.9;
    // model.children[0].material.roughness = 0.5;
    // model.children[2].material.metalness = 0.9;
    // model.children[2].material.roughness = 0.5;
    // model.children[4].material.metalness = 0.9;
    // model.children[4].material.roughness = 0.5;
    // model.children[5].material.metalness = 0.9;
    // model.children[5].material.roughness = 0.5;
    scene3.add(model);
    model.position.set(0, -0.5, 0);
    model.castShadow = true;
}, undefined, function(error) {
    console.error(error)
})

/**
 * Add plane
 */
const planeGeometry = new THREE.PlaneGeometry(30,30);
const planeMaterial = new THREE.MeshStandardMaterial({color: 0x3a3b3c, side: THREE.DoubleSide})
const plane0 = new THREE.Mesh(planeGeometry, planeMaterial);
const plane1 = new THREE.Mesh(planeGeometry, planeMaterial);
const plane2 = new THREE.Mesh(planeGeometry, planeMaterial);
const plane3 = new THREE.Mesh(planeGeometry, planeMaterial);
const plane4 = new THREE.Mesh(planeGeometry, planeMaterial);
scene0.add(plane0);
scene1.add(plane1);
scene2.add(plane2);
scene3.add(plane3);
scene4.add(plane4);

plane0.position.set(0, -0.5, 0);
plane0.rotation.x = -0.5 * Math.PI;
plane0.receiveShadow = true;

plane1.position.set(0, -0.5, 0);
plane1.rotation.x = -0.5 * Math.PI;
plane1.receiveShadow = true;

plane2.position.set(0, -0.5, 0);
plane2.rotation.x = -0.5 * Math.PI;
plane2.receiveShadow = true;

plane3.position.set(0, -0.5, 0);
plane3.rotation.x = -0.5 * Math.PI;
plane3.receiveShadow = true;

plane4.position.set(0, -0.5, 0);
plane4.rotation.x = -0.5 * Math.PI;
plane4.receiveShadow = true;

/**
 * Animate
 */
const clock = new THREE.Clock();
const clock2 = new THREE.Clock();
const clock3 = new THREE.Clock();
function animate(time) {
    if(mixer)
        mixer.update(clock.getDelta())
    if(mixer2)
        mixer2.update(clock2.getDelta());
    if(mixer3)
        mixer3.update(clock3.getDelta());
    box.rotation.x = time / 1000;
    box.rotation.y = time / 1000;
    orbit.update();
    
    


    // step += options.speed;
    // sphere.position.y = 10 * Math.abs(Math.sin(step));

    renderer.render(window.currentScene, camera);
}

renderer.setAnimationLoop(animate);

/**
 * Adjust window size
 */
window.addEventListener('resize', function() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
})


window.addEventListener('switch-scenes', (e)=> {
    
    switch (e.detail.scene) {
        case 'first': {
            window.currentScene = scene0;
            break
        }
        case 'second': {
            window.currentScene = scene1;
            break;
        }
        case 'third': {
            window.currentScene = scene2;
            break;
        }
        case 'fourth': {
            window.currentScene = scene3;
            break;
        }
        case 'fifth': {
            window.currentScene = scene4;
            break;
        }
    }
});

